<template>
  <thead class="text-[#757A8A] text-[0.875rem] font-[500] bg-[#F4F6FA] font-poppins w-full" >
  <tr>
    <td class="pl-3 py-3">Campaign Name</td>
    <td v-if="tableColumns[2].status" class="py-3">Campaign Type</td>
    <td v-if="tableColumns[1].status" class="py-3">CTA Type</td>
    <td v-if="tableColumns[5].status" class="py-3">Headline</td>
    <td v-if="tableColumns[3].status" class="py-3 text-center">{{ getSiteDetails.agency_name }} Links</td>
    <td v-if="tableColumns[6].status" class="py-3 text-center">Clicks</td>
    <td v-if="tableColumns[9].status" class="py-3 text-center">Unique Clicks</td>
    <td v-if="tableColumns[7].status" class="py-3 text-center">Conv.</td>
    <td v-if="tableColumns[8].status" class="py-3 text-center">Conv. rate</td>
    <td v-if="tableColumns[4].status" class="py-3 text-center">Created</td>
    <td class="pr-3 py-3 text-right">More</td>
  </tr>
  </thead>
</template>
<script>
export default {
  props: ['tableColumns']
}
</script>
