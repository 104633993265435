<template>
  <div class="pb-6 pt-[84px] px-6 w-full h-screen bg-[#F7F7FC]">
    <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-lg">
      <div class="flex flex-col justify-between h-full">
        <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
          <div class="flex px-3 py-3.5 justify-between items-center">
            <div class="w-full flex gap-x-2 justify-start items-center">
              <Button
                id="campaign-button"
                type="button"
                class="bg-blue-600 hover:bg-blue-700 !h-[2.0rem]"
                buttonClass="btn-sm"
                @click="createCampaignModal()"
              >
                <template v-slot:label>Create New</template>
              </Button>
              <SearchField
                id="search-workspace"
                v-model="getCampaigns.search"
                @input="campaignSearch()"
                type="text"
                label="Search Campaigns"
                iconName="lock.svg"
                customClass="h-8 bg-white with_border"
              >
                <template v-slot:right_btn>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 17 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2.95166 8.22033C2.95166 5.32428 5.29938 2.97656 8.19543 2.97656C11.0915 2.97656 13.4392 5.32428 13.4392 8.22033C13.4392 9.6277 12.8848 10.9056 11.9824 11.8475C11.9532 11.8708 11.9249 11.896 11.8979 11.923C11.8708 11.9501 11.8457 11.9783 11.8224 12.0075C10.8805 12.9097 9.6027 13.4641 8.19543 13.4641C5.29938 13.4641 2.95166 11.1164 2.95166 8.22033ZM12.5616 14.0009C11.3475 14.9193 9.83508 15.4641 8.19543 15.4641C4.19481 15.4641 0.95166 12.221 0.95166 8.22033C0.95166 4.21971 4.19481 0.976562 8.19543 0.976562C12.1961 0.976562 15.4392 4.21971 15.4392 8.22033C15.4392 9.86011 14.8943 11.3726 13.9758 12.5867L16.7071 15.3181C17.0977 15.7086 17.0977 16.3418 16.7071 16.7323C16.3166 17.1228 15.6835 17.1228 15.2929 16.7323L12.5616 14.0009Z"
                          fill="#757A8A"/>
                  </svg>
                </template>
              </SearchField>
            </div>
            <div class="w-full flex items-center justify-center">
              <p class="text-[#3C4549] mr-1 text-lg font-semibold font-poppins leading-none">Campaigns</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
                      fill="#3C4549"/>
              </svg>
            </div>
            <div class="w-full flex gap-x-2 items-center justify-end">
              <div class="float-right">
                <b-dropdown
                  id="dropdown_archive_campaign"
                  ref="dropdown_archive_campaign"
                  right
                  class=" dropdown-menu-right hide_dropdown_caret  default_style_dropdown"
                  :no-caret="true"
                >
                  <div
                    class=" dropdown_header border !border-[#757A8A] font-semibold h-8 px-2.5 py-2 rounded-lg d-flex align-items-center "
                    slot="button-content">
                    <div class="w-full flex justify-between items-center">
                      <div>
                        <p v-if="showArchiveDropdown !== true" class="text-[14px] text font-normal font-poppins">Show
                          Archived</p>
                        <p v-if="showArchiveDropdown === true" class="text-[14px] text font-normal font-poppins">Show
                          Unarchived</p>
                      </div>
                      <span class="arrow_icon ml-auto"><i
                        class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
                    </div>
                  </div>
                  <transition name="fade">
                    <ul class=" text-[#202324] py-1 text-[0.875rem] shadow-lg bg-white rounded-lg font-poppins">
                      <li v-if="showArchiveDropdown !== true"
                          @click.prevent="showCampaignArchive(true),showArchiveDropdown = true, $refs.dropdown_archive_campaign.hide(true)"
                          class="list_item_li !hover:bg-[#F4F6FA] !py-3">
                        <div class="flex items-center ">
                          <div class="content">
                            <div class="text">
                              Show Archived
                            </div>
                          </div>
                        </div>
                      </li>
                      <li v-if="showArchiveDropdown === true"
                          @click.prevent="showCampaignArchive(false), showArchiveDropdown = false, $refs.dropdown_archive_campaign.hide(true)"
                          class="list_item_li !hover:bg-[#F4F6FA] !py-3">
                        <div class="flex items-center ">
                          <div class="content">
                            <div class="text">
                              Show Unarchived
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </transition>
                </b-dropdown>
              </div>
              <DatePicker
                className="campaigns_date_filter"
                customClass="with_border"
                @input="dateFilter"
              ></DatePicker>
              <div class="manage-dropdown shortcut_menu_dropdown">
                <b-dropdown offset="25, 0"
                            right
                            class="dropdown-menu-left hide_caret default_style_dropdown"
                            ref="campaign_dropdown_select"
                            :no-caret="true">
                  <div
                    class="dropdown_header border !border-[#757A8A] font-semibold h-8 px-2.5 py-2 rounded-lg d-flex align-items-center "
                    slot="button-content">
                    <span class="text">Hide/Show Columns</span>
                    <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
                  </div>
                  <transition>
                    <ul class=" max-h-[300px] !overflow-y-scroll ">

                      <li class="border-b py-[0.75rem] px-[1rem]">
                        <Checkbox
                          id="campaign_column_select_all"
                          v-model="checkAllColumn"
                          @change="ctaColumnSelectAll()"
                          label="All Columns"
                          labelClass="text-[#3C4549] font-semibold"
                          customClass="w-full justify-between flex-row-reverse"
                        ></Checkbox>
                      </li>
                      <li v-for="(column,index) in tableColumns" class="py-[0.75rem] px-[1rem]">

                        <Checkbox
                          :id="'column'+ column.id"
                          v-model="column.status"
                          @change="campaignsSelectedColumnsCount()"
                          :label="column.name"
                          labelClass="text-[#3C4549]"
                          customClass="w-full justify-between flex-row-reverse"
                        ></Checkbox>
                      </li>
                    </ul>
                  </transition>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="">
            <table class="w-full text-left">
              <TableHead :tableColumns="tableColumns"/>
              <TableBody :tableColumns="tableColumns"/>
            </table>
          </div>
        </div>
        <CampaignsPaginationV2></CampaignsPaginationV2>
      </div>
    </div>
  </div>


</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

@-moz-document url-prefix() {
  .white_box .box_inner .box_head .right.more_width_500 {
    min-width: 560px !important;
  }
}

.toggle_btn_inner {
  width: 104% !important;
}
</style>

<script>

import {mapActions, mapGetters} from 'vuex'
import CampaignsList from './table/CampaignsList'
import CampaignsListHeader from './table/CampaignsListHeader'
import Headerbar from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Modal from '@/misc/Modal.vue'
import Dropdown from '@/ui/Dropdown.vue'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'
import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import {campaignTypes, linkTypes, widgetTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import SearchField from '@/ui/ui-kit/v2/SearchField.vue'
import TableHead from '@/components/campaign/table/TableHead.vue'
import TableBody from '@/components/campaign/table/TableBody.vue'
import Button from '@/ui/ui-kit/v2/Button.vue'

export default ({
  'components': {
    TableHead,
    TableBody,
    Button,
    Headerbar,
    SearchField,
    Sidebar,
    Modal,
    Dropdown,
    GuidedTour,
    ConfirmationBox,
    CampaignsList,
    CampaignsListHeader,
    CampaignsPaginationV2: () => import('@/components/campaign/CampaignsPaginationV2.vue'),
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue'),
    DatePicker: () => import('@/ui/ui-kit/v2/DatePicker.vue'),
  },
  data: function () {
    return {
      size_small: '18px',
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      checkAllColumn: false,
      tableColumns: [
        {id: 1, name: 'Brand', status: true},
        {id: 2, name: 'CTA Type', status: true},
        {id: 3, name: 'Campaign Type', status: true},
        {id: 4, name: 'Replug Links', status: true},
        {id: 5, name: 'Created', status: true},
        {id: 6, name: 'Headline', status: true},
        {id: 7, name: 'Clicks', status: true},
        {id: 8, name: 'Conversions', status: true},
        {id: 9, name: 'Conv. rate', status: true},
        {id: 10, name: 'Unique Clicks', status: true}
      ],
      previousPage: 1,
      debounce: null,
      showArchiveDropdown: false,

    }
  },
  mounted() {
    let self = this
    setTimeout(function () {
      self.callCampaignDatePicker()
    }, 500)
    this.tableColumns[3].name = this.getSiteDetails.agency_name + ' Links'
  },

  async created() {
    this.resetCampaignFilters(true)
    await this.fetchCampaignOnFetchedStatus()
    await this.fetchDomains()
    await this.fetchCampaignAnalytics()
    await this.fetchWidgets({page: -1})
    await this.fetchPixels()
  },

  computed: {

    ...mapGetters([
      'getCampaignsList',
      'getCampaigns',
      'getCampaignLoaders',
      'getGuidedTour',
      'getBrands',
      'getModalData',
      'getSubscription',
      'getComponentCampaignList',
      'getComponentBrandList',
      'getBrandLoaders'
    ])
  },

  methods: {

    ...mapActions([
      'fetchCampaignsList',
      'archiveCampaign',
      'setAllBrandsFetchStatus',
      'fetchCampaignAnalytics',
      'fetchBrandList',
      'fetchDomains',
      'fetchWidgets',
      'fetchPixels',
      'setComponentCampaignList'
    ]),
    dateFilter(event) {
      this.$store.commit(campaignTypes.SET_CAMPAIGNS_DATE_VALUE, event)
      this.paginateCampaigns(1)
    },
    createCampaignModal() {
      let limits = this.getSubscription.limits

      if (limits && limits.campaign && this.getComponentCampaignList.length >= this.getComponentCampaignList.limits.campaign) {
        setTimeout(() => {
          this.$bvModal.show('upgrade-subscription-modal')
        }, 100)
        this.alertMessage('You have reached maximum limit of your subscription plan, please upgrade your plan to unlock more features.', 'error')
        return false
      }
      this.$store.commit(widgetTypes.RESET_WIDGET)
      this.$store.commit(campaignTypes.RESET_CAMPAIGN_ADD)
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
      this.$store.commit(linkTypes.SET_HAS_LINK_CUSTOM_MESSAGE, false)
      this.$store.commit(linkTypes.SET_LINK_CUSTOM_MESSAGE, 'Your message goes here')
      this.$store.commit(linkTypes.SET_LINK_CUSTOM_MESSAGE_HEADLINE, 'Your headline goes here')
      this.$router.push({name: 'create'})
    },
    async showCampaignArchive(value) {
      if (!this.getCampaignLoaders.retrieve) {
        this.$store.commit(campaignTypes.SET_CAMPAIGNS_ARCHIVE_VALUE, value)
        this.fetchCampaignByFilters()
      }
    },

    /**
     * Fetching the record on the basis of page number
     * @param page
     * @returns {Promise<void>}
     */
    async paginateCampaigns(page) {
      this.getCampaigns.page = page

      // if page is one then no need to check periodic
      if (page !== 1) {
        this.getCampaigns.periodic = this.isFetchedPeriodic(this.previousPage, page, this.getCampaigns.periodic)
      }

      // if isAllListFetched true from other source like dropdown then no need to set again isAllListFetched
      if (!this.getCampaigns.isAllListFetched) {
        // isAllListFetched will only be true only when all record fetched in periodic way
        this.getCampaigns.isAllListFetched = this.getCampaigns.periodic && (page >= this.getCampaigns.total_pages)
      }

      // if previous page is equal to current page then fetch only analytics
      if (this.previousPage === page) {
        this.fetchCampaignAnalytics()
        return
      }

      // setting the previousPage
      this.previousPage = JSON.parse(JSON.stringify(page))

      // if pagination is not in periodic way then get record from api
      if (!this.getCampaigns.periodic && !this.getCampaigns.isAllListFetched) {
        this.$store.commit(campaignTypes.SET_CAMPAIGN_LIST, [])
        await this.fetchCampaignsList({page: page})
        await this.setComponentCampaignList(this.getCampaignsList)
        this.fetchCampaignAnalytics()
        // resetting the periodic on page 1 and previousPage
        if (page === 1) {
          this.getCampaigns.periodic = true
          this.previousPage = 1
        }
        return
      }

      // chunck the local record if exist on the basis of pagination otherwise get from DB
      const list = this.getCampaignsList.slice((page - 1) * this.getCampaigns.limit, page * this.getCampaigns.limit)
      if (list && list.length) {
        await this.setComponentCampaignList(list)
        this.fetchCampaignAnalytics()
        return
      }

      // if no local record found then get from DB
      await this.fetchCampaignsList({page: page})
      await this.setComponentCampaignList(this.getCampaignsList.slice((page - 1) * this.getCampaigns.limit, page * this.getCampaigns.limit))
      this.fetchCampaignAnalytics()
    },

    columnsStatusChanged(status) {
      for (let i = 0; i < this.tableColumns.length; i++) {
        if (this.tableColumns[i].status === false) {
          this.checkAllColumn = false
        }
      }
    },

    callCampaignDatePicker() {
      let me = this
      $(document).ready(function () {
        $('.campaignDatePicker').daterangepicker({
          opens: 'left',
          parentEl: 'body',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate: moment().startOf('day').subtract(1, 'hour'),
          endDate: moment().endOf('day').subtract(1, 'hour'),
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        })
        $('.campaignDatePicker').on('show.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').addClass('shown')
        })

        $('.campaignDatePicker').on('hide.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').removeClass('shown')
        })

        $('.campaignDatePicker').on('apply.daterangepicker', function (ev, picker) {
          let range = null
          if (picker.chosenLabel != ' All Time') {
            range = {
              label: picker.chosenLabel,
              value: picker.startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + picker.endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          } else {
            range = {
              label: picker.chosenLabel,
              value: moment().subtract(5, 'years').format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + moment().format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          // calling the content mixing method to reflect the range filter.
          me.$store.commit(campaignTypes.SET_CAMPAIGNS_DATE_VALUE, range)
          me.fetchCampaignByFilters()
        })
      })
    },

    /**
     * Fetching the campaign on the basis of date, search, filters
     */
    async fetchCampaignByFilters() {
      this.getBrand.isListFetched = false
      this.getBrand.periodic = true
      this.getBrand.isAllListFetched = false
      this.previousPage = 1
      this.$store.commit(campaignTypes.SET_CAMPAIGN_LIST, [])
      this.$store.commit(campaignTypes.SET_COMPONENT_CAMPAIGN_LIST, [])
      await this.fetchCampaignsList({page: 1})
      await this.setComponentCampaignList(this.getCampaignsList)
      this.fetchCampaignAnalytics()
    },

    ctaColumnSelectAll() {
      if (this.checkAllColumn) {
        this.tableColumns.map(item => item.status = true)
      } else {
        this.tableColumns.map(item => item.status = false)
      }
    },
    async selectAllCampaigns() {
      const select = this.getCampaigns.select_all
      if (!select) {
        const brandIdsList = this.getComponentBrandList.map(item => item._id)
        await this.$store.commit(campaignTypes.SET_CAMPAIGN_BRAND_FILTERS, brandIdsList)
      } else {
        await this.$store.commit(campaignTypes.SET_CAMPAIGN_BRAND_FILTERS, [])
      }
      this.fetchCampaignAnalytics()
    },
    campaignsSelectedColumnsCount() {
      this.checkAllColumn = (this.tableColumns.filter(item => item.status === true).length === this.tableColumns.length)
    },
    selectedBrands() {
      this.fetchCampaignByFilters()
    },
    campaignSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchCampaignByFilters()
      }, 600)
    }
  },
  watch: {
    'getCampaigns.selected_brands.length'(value) {
      const checkboxValue = (this.getComponentBrandList.length === value)
      this.$set(this.getCampaigns, 'select_all', checkboxValue)
    }
  }

})
</script>
