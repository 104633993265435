<template>
  <tbody class="font-poppins text-[0.875rem] font-[500]">
  <ArchiveCampaignModalV2 :archiveCampaignsObj="archiveCampaignsObj"></ArchiveCampaignModalV2>
  <DeleteCampaignModalV2 :deleteCampaignObj="deleteCampaignObj"></DeleteCampaignModalV2>
  <tr v-if="getCampaignLoaders.retrieve">
    <td colspan="12">
      <p style="padding: 10px;">
        <clip-loader :size="size" :color="color"></clip-loader>
      </p>
    </td>
  </tr>
  <tr v-else-if="getComponentCampaignList.length === 0 " >
    <td colspan="12">
      <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
          getFilteredMessage('campaign', getCampaigns.search, getCampaigns.date.label, getCampaigns.archive, getCampaigns.selected_brands)
        }}</p>
    </td>
  </tr>

  <tr v-else v-for="(campaign,index) in getComponentCampaignList" :index="index" :key="campaign._id" class="bg-white border-b">
    <td class="px-3 py-4">{{ campaign.name }}</td>
    <td v-if="tableColumns[2].status" class="py-4">{{ findCampaignType(campaign) }}</td>
    <td v-if="tableColumns[1].status" class="py-4">
      <span v-if="campaign" style="text-transform: capitalize;"
            :class="{'light_grey' : campaign.type === 'no_cta'
        || campaign.type === 'retargeting' || campaign.type === 'integration'}">
      <template
        v-if="campaign.type === 'no_cta' || campaign.type === 'retargeting' || campaign.type === 'integration'">
          N/A
      </template>
      <template v-else>
           {{ campaign.type }}
      </template>
  </span>
</td>
    <td v-if="tableColumns[5].status" class="py-4">
      <span v-if="campaign">
                                                <span v-if="campaign.type !== 'retargeting' && campaign.type !== 'integration'
                                                && campaign.cta_type !== 'social_modern' && campaign.type !== 'no_cta'">
                                                        {{ campaign.message_headline }}
                                                </span>
                                                 <span class="light_grey" v-else>N/A</span>
                                            </span>
      <span v-else style="color: red;">Missing</span>
    </td>
    <template v-if="tableColumns[3].status">
      <td class="text-center" v-if="campaign.total_links">
        <div :class="{'overlay_item archived':getCampaigns.archive}">
          {{ commaSeparatedNumber(campaign.total_links) }}
        </div>

      </td>
      <td class="text-center" v-else>
        <div :class="{'overlay_item archived':getCampaigns.archive}">
          0
        </div>
      </td>
    </template>
    <td v-if="tableColumns[6].status" class="text-center py-4">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ commaSeparatedNumber(campaign.total_visitors) }}
      </div>
      <skeleton v-else></skeleton>
    </td>
    <td v-if="tableColumns[9].status" class="text-center py-4">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ commaSeparatedNumber(campaign.unique_visitors) }}
      </div>
      <skeleton v-else></skeleton>
    </td>
    <td v-if="tableColumns[7].status" class="text-center py-4">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ commaSeparatedNumber(campaign.total_clicks_and_conversions) }}
      </div>
      <skeleton v-else></skeleton>
    </td>
    <td v-if="tableColumns[8].status" class="text-center py-4">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ campaign.unique_visitors_conversion_rate }}%
      </div>
      <skeleton v-else></skeleton>
    </td>
    <td v-if="tableColumns[4].status" class="text-center py-4">
      <div :class="{'overlay_item archived':getCampaigns.archive}">
        {{ campaign.created_at | relativeCaptilize }}
      </div>

    </td>
    <td class="text-right pr-3 py-4">

      <div class="flex justify-end items-center" v-if="campaign.archive">
        <template v-if="campaign.policy.edit">

<!--          <i v-tooltip="'Unarchive'" class="far fa-recycle" data-cy="unarchive"-->
<!--             @click.prevent="archiveCampaignModal(campaign._id,index,campaign.user_id, false)"-->
<!--             aria-hidden="true"></i>-->


          <svg v-tooltip="'Unarchive'" @click.prevent="$bvModal.show('modal_archive_campaign'), archiveCampaignsObj={campaignId:campaign._id, index:index, userId:campaign.user_id, status:false}"
               data-cy="b-archive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none"
               class="w-5 h-5">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.04256 2.44727C2.74291 2.44727 2.5 2.69018 2.5 2.98983V6.60689C2.5 6.90654 2.74291 7.14945 3.04256 7.14945H3.94678V16.0094C3.94678 16.3091 4.18969 16.552 4.48934 16.552H17.5108C17.8104 16.552 18.0533 16.3091 18.0533 16.0094V7.14945H18.9576C19.2573 7.14945 19.5002 6.90654 19.5002 6.60689V2.98983C19.5002 2.69018 19.2573 2.44727 18.9576 2.44727H3.04256ZM17.5556 6.06433C17.5408 6.06312 17.5259 6.0625 17.5108 6.0625C17.4957 6.0625 17.4807 6.06312 17.4659 6.06433H4.5342C4.51941 6.06312 4.50444 6.0625 4.48934 6.0625C4.47423 6.0625 4.45927 6.06312 4.44447 6.06433H3.58512V3.53238H18.4151V6.06433H17.5556ZM5.0319 7.14945H16.9682V15.4669H5.0319V7.14945ZM7.45744 9.5C7.45744 9.20035 7.70035 8.95744 8 8.95744H14C14.2996 8.95744 14.5426 9.20035 14.5426 9.5C14.5426 9.79965 14.2996 10.0426 14 10.0426H8C7.70035 10.0426 7.45744 9.79965 7.45744 9.5ZM8 11.9574C7.70035 11.9574 7.45744 12.2004 7.45744 12.5C7.45744 12.7996 7.70035 13.0426 8 13.0426H14C14.2996 13.0426 14.5426 12.7996 14.5426 12.5C14.5426 12.2004 14.2996 11.9574 14 11.9574H8Z"
                  fill="#3C4549"></path>
            <path d="M20.5 1.5L1.5 18.5" stroke="#3C4549" stroke-width="1.08512" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>


        </template>
        <template v-else>
          -
        </template>
      </div>
      <template v-else>

        <b-dropdown ref="dropdownActionMenu" offset="-30"
                    class="dropdown-menu-right hide_dropdown_caret default_style_dropdown"
                    no-caret
                    right
                    >
          <!--d-flex align-items-center-->
          <div  class="dropdown_header justify-end items-center gap-2.5 flex" slot="button-content">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <circle cx="10.0002" cy="9.99935" r="1.66667" transform="rotate(-90 10.0002 9.99935)" fill="#3C4549"/>
              <circle cx="3.33317" cy="9.99935" r="1.66667" transform="rotate(-90 3.33317 9.99935)" fill="#3C4549"/>
              <circle cx="16.6667" cy="9.99935" r="1.66667" transform="rotate(-90 16.6667 9.99935)" fill="#3C4549"/>
            </svg>
          </div>

          <transition name="fade">
            <ul class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
              <div @click.prevent="campaignDetailView(campaign.brand, campaign)" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.72226 0.888584C6.72226 0.397664 6.32429 -0.000305176 5.83337 -0.000305176C5.34245 -0.000305176 4.94449 0.397664 4.94449 0.888584V15.1108C4.94449 15.6017 5.34245 15.9997 5.83337 15.9997C6.32429 15.9997 6.72226 15.6017 6.72226 15.1108V0.888584ZM11.0558 6.22257C11.0558 5.73165 10.6578 5.33368 10.1669 5.33368C9.67595 5.33368 9.27798 5.73165 9.27798 6.22257V15.1115C9.27798 15.6024 9.67595 16.0003 10.1669 16.0003C10.6578 16.0003 11.0558 15.6024 11.0558 15.1115V6.22257ZM1.50009 8.8888C1.99101 8.8888 2.38898 9.28677 2.38898 9.77769V15.111C2.38898 15.6019 1.99101 15.9999 1.50009 15.9999C1.00918 15.9999 0.611206 15.6019 0.611206 15.111V9.77769C0.611206 9.28677 1.00918 8.8888 1.50009 8.8888ZM15.3888 2.77726C15.3888 2.28634 14.9908 1.88837 14.4999 1.88837C14.009 1.88837 13.611 2.28634 13.611 2.77726V15.1106C13.611 15.6015 14.009 15.9995 14.4999 15.9995C14.9908 15.9995 15.3888 15.6015 15.3888 15.1106V2.77726Z" fill="#3C4549"/>
                </svg>
                <p>View Stats</p>
              </div>

              <div v-if="!campaign.is_default" @click.prevent="editCampaign(campaign)" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 16" fill="none">
                  <path d="M11.2271 1.95798C11.6004 1.58471 12.1067 1.375 12.6346 1.375C13.1625 1.375 13.6687 1.58471 14.042 1.95798C14.4153 2.33126 14.625 2.83753 14.625 3.36543C14.625 3.89332 14.4153 4.3996 14.042 4.77288L5.12819 13.6867L1.375 14.625L2.3133 10.8718L11.2271 1.95798Z" stroke="#3C4549" stroke-width="1.24219" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p>Edit</p>
              </div>
              <div v-if="!campaign.is_default" @click.prevent="duplicateCampaign(campaign)" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 18 18" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.185 2.185C2.35293 2.01707 2.58069 1.92273 2.81818 1.92273H9.77273C10.0102 1.92273 10.238 2.01707 10.4059 2.185C10.5738 2.35293 10.6682 2.58069 10.6682 2.81818V3.59091C10.6682 3.94989 10.9592 4.24091 11.3182 4.24091C11.6772 4.24091 11.9682 3.94989 11.9682 3.59091V2.81818C11.9682 2.23591 11.7369 1.67749 11.3251 1.26576C10.9134 0.854033 10.355 0.622727 9.77273 0.622727H2.81818C2.23591 0.622727 1.67749 0.854033 1.26576 1.26576C0.854033 1.67749 0.622727 2.23591 0.622727 2.81818V9.77273C0.622727 10.355 0.854033 10.9134 1.26576 11.3251C1.67749 11.7369 2.23591 11.9682 2.81818 11.9682H3.59091C3.94989 11.9682 4.24091 11.6772 4.24091 11.3182C4.24091 10.9592 3.94989 10.6682 3.59091 10.6682H2.81818C2.58069 10.6682 2.35293 10.5738 2.185 10.4059C2.01707 10.238 1.92273 10.0102 1.92273 9.77273V2.81818C1.92273 2.58069 2.01707 2.35293 2.185 2.185ZM7.33182 8.22727C7.33182 7.73273 7.73273 7.33182 8.22727 7.33182H15.1818C15.6764 7.33182 16.0773 7.73273 16.0773 8.22727V15.1818C16.0773 15.6764 15.6764 16.0773 15.1818 16.0773H8.22727C7.73273 16.0773 7.33182 15.6764 7.33182 15.1818V8.22727ZM8.22727 6.03182C7.01476 6.03182 6.03182 7.01476 6.03182 8.22727V15.1818C6.03182 16.3943 7.01476 17.3773 8.22727 17.3773H15.1818C16.3943 17.3773 17.3773 16.3943 17.3773 15.1818V8.22727C17.3773 7.01476 16.3943 6.03182 15.1818 6.03182H8.22727Z" fill="#3C4549"/>
                </svg>
                <p> Duplicate</p>
              </div>
              <div v-if="!campaign.is_default" @click.prevent="$bvModal.show('modal_archive_campaign'), archiveCampaignsObj = {campaignId:campaign._id, index:index, userId:campaign.user_id, status:true}" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 18 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04256 0.947266C0.742912 0.947266 0.5 1.19018 0.5 1.48983V5.10689C0.5 5.40654 0.742912 5.64945 1.04256 5.64945H1.94678V14.5094C1.94678 14.8091 2.18969 15.052 2.48934 15.052H15.5108C15.8104 15.052 16.0533 14.8091 16.0533 14.5094V5.64945H16.9576C17.2573 5.64945 17.5002 5.40654 17.5002 5.10689V1.48983C17.5002 1.19018 17.2573 0.947266 16.9576 0.947266H1.04256ZM15.5556 4.56433C15.5408 4.56312 15.5259 4.5625 15.5108 4.5625C15.4957 4.5625 15.4807 4.56312 15.4659 4.56433H2.5342C2.51941 4.56312 2.50444 4.5625 2.48934 4.5625C2.47423 4.5625 2.45927 4.56312 2.44447 4.56433H1.58512V2.03238H16.4151V4.56433H15.5556ZM3.0319 5.64945H14.9682V13.9669H3.0319V5.64945ZM5.45744 8C5.45744 7.70035 5.70035 7.45744 6 7.45744H12C12.2996 7.45744 12.5426 7.70035 12.5426 8C12.5426 8.29965 12.2996 8.54256 12 8.54256H6C5.70035 8.54256 5.45744 8.29965 5.45744 8ZM6 10.4574C5.70035 10.4574 5.45744 10.7004 5.45744 11C5.45744 11.2996 5.70035 11.5426 6 11.5426H12C12.2996 11.5426 12.5426 11.2996 12.5426 11C12.5426 10.7004 12.2996 10.4574 12 10.4574H6Z" fill="#3C4549"/>
                </svg>
                <p>Archive</p>
              </div>
              <div v-if="!campaign.is_default" @click.prevent="$bvModal.show('modal_delete_campaign_v2'), deleteCampaignObj = {campaignId: campaign._id,index: index}"  class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 18" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63083 1.72174C5.82519 1.52739 6.08879 1.4182 6.36365 1.4182H9.63638C9.91124 1.4182 10.1748 1.52739 10.3692 1.72174C10.5636 1.9161 10.6727 2.1797 10.6727 2.45456V3.49093H5.32729V2.45456C5.32729 2.1797 5.43647 1.9161 5.63083 1.72174ZM4.12729 3.49093V2.45456C4.12729 1.86144 4.3629 1.29262 4.7823 0.873216C5.2017 0.453817 5.77053 0.218201 6.36365 0.218201H9.63638C10.2295 0.218201 10.7983 0.453817 11.2177 0.873216C11.6371 1.29262 11.8727 1.86144 11.8727 2.45456V3.49093H13.7273H15.3636C15.695 3.49093 15.9636 3.75956 15.9636 4.09093C15.9636 4.4223 15.695 4.69093 15.3636 4.69093H14.3273V15.5455C14.3273 16.1386 14.0917 16.7074 13.6723 17.1268C13.2529 17.5462 12.684 17.7818 12.0909 17.7818H3.9091C3.31598 17.7818 2.74716 17.5462 2.32776 17.1268C1.90836 16.7074 1.67274 16.1386 1.67274 15.5455V4.69093H0.636377C0.305006 4.69093 0.036377 4.4223 0.036377 4.09093C0.036377 3.75956 0.305006 3.49093 0.636377 3.49093H2.27274H4.12729ZM2.87274 4.69093H4.72729H11.2727H13.1273V15.5455C13.1273 15.8203 13.0181 16.0839 12.8237 16.2783C12.6294 16.4726 12.3658 16.5818 12.0909 16.5818H3.9091C3.63424 16.5818 3.37064 16.4726 3.17628 16.2783C2.98193 16.0839 2.87274 15.8203 2.87274 15.5455V4.69093ZM6.36365 7.58184C6.69502 7.58184 6.96365 7.85047 6.96365 8.18184V13.0909C6.96365 13.4223 6.69502 13.6909 6.36365 13.6909C6.03228 13.6909 5.76365 13.4223 5.76365 13.0909V8.18184C5.76365 7.85047 6.03228 7.58184 6.36365 7.58184ZM10.2364 8.18184C10.2364 7.85047 9.96775 7.58184 9.63638 7.58184C9.30501 7.58184 9.03638 7.85047 9.03638 8.18184V13.0909C9.03638 13.4223 9.30501 13.6909 9.63638 13.6909C9.96775 13.6909 10.2364 13.4223 10.2364 13.0909V8.18184Z" fill="black"/>
                </svg>
                <p>Remove</p>
              </div>
            </ul>
          </transition>
        </b-dropdown>
      </template>
    </td>
  </tr>
  </tbody>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {campaignTypes, userTypes} from "@/state/modules/mutation-types";
import ArchiveCampaignModalV2 from "@/components/campaign/ArchiveCampaignModalV2.vue";
import DeleteCampaignModalV2 from "@/components/campaign/DeleteCampaignModalV2.vue";
import CampaignsPaginationV2 from "@/components/campaign/CampaignsPaginationV2.vue";

export default {
  components: {CampaignsPaginationV2, DeleteCampaignModalV2, ArchiveCampaignModalV2},
  data () {
    return {
      archiveCampaignsObj: {},
      deleteCampaignObj: {}
    }
  },
  props: ['tableColumns'],
  computed: {
    ...mapGetters([
      'getCampaigns',
      'getComponentCampaignList'
    ])
  },
  methods: {
    ...mapActions([
      'deleteCampaign',
    ]),
    findCampaignType (campaign) {
      switch (campaign.type) {
        case 'no_cta':
          return 'Shortener'
        case 'retargeting':
          return 'Retargeting'
        case 'integration':
          return '3rd Party Widget'
        default:

          if (campaign.campaign_type && campaign.campaign_type.retargeting === true) {
            return 'Call-to-Action | Retargeting'
          } else if (campaign.campaign_type) {
            return 'Call-to-Action'
          } else {
            return 'N/A'
          }
      }
    },
    editCampaign (campaign) {
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, campaign)
      this.$router.push({name: 'campaign_type'})
    },
    duplicateCampaign (campaign) {
      let newCampaign = JSON.parse(JSON.stringify(campaign))
      newCampaign.total_links = 0
      newCampaign.total_visitors = 0
      newCampaign.conversions = 0
      newCampaign.unique_visitors = 0
      newCampaign.total_clicks = 0
      newCampaign.total_clicks_and_conversions = 0
      newCampaign.conversion_rate = 0
      newCampaign.unique_visitors_conversion_rate = 0
      newCampaign.clicks = 0
      newCampaign.name = newCampaign.name + ' - Duplicate'
      delete newCampaign.created_at
      delete newCampaign.updated_at
      newCampaign._id = null
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, newCampaign)
      this.$router.push({name: 'campaign_type'})
    },
    archiveCampaignModal (campaign, index, userId, status) {
      this.$store.commit(userTypes.SET_MODAL_DATA, {
        campaign: campaign,
        user_id: userId,
        status: status,
        index: index
      })
      if (this.getProfile.hide_archive_message) {
        this.$store.dispatch('archiveCampaign')
      } else {
        $('#archiveCampaign').modal('show')
      }
    },
  }
}
</script>
