<template>
  <div class="py-[0.78rem]">
    <template>
      <div class="flex px-6 w-full justify-between items-center">
        <div class="w-full flex justify-center items-center">
          <div v-if="getCampaigns.total_items > getCampaigns.limit">
            <TablePagination
              :clickedMethod="paginateCampaigns"
              :resultCount="getCampaigns.total_items"
              :itemsPerPage="getCampaigns.limit"
              :currentPage="getCampaigns.page"
              :totalPagesNumber="getCampaigns.total_pages"
            ></TablePagination>
          </div>
        </div>
        <PerPageFilter
          :limit="getCampaigns.limit"
          @click="getCampaigns.limit = $event; paginateCampaigns()"
        ></PerPageFilter>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue'),
    PerPageFilter: () => import('@/ui/ui-kit/v2/PerPageFilter.vue'),
  },
  computed: {
    ...mapGetters([
      'getCampaigns'
    ]),

  },
  methods: {
    ...mapActions([
      'fetchCampaignsList'
    ]),
    async paginateCampaigns (page) {
      await this.$store.dispatch('fetchCampaignsList', page)
    }
  }
}
</script>
